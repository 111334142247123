interface ContentType {
  ticker: string;
}

interface Region {
  language: string;
  contentTypes: ContentType;
}

// this mapping is part of the the region mapping of contentful proxy
// it is used to check which locales are using the same contentful ticker
export const regionMapping: { [key: string]: Region[] } = {
  ad: [
    {
      language: 'fr',
      contentTypes: { ticker: 'tickerFr' },
    },
    {
      language: 'es',
      contentTypes: { ticker: 'tickerEs' },
    },
  ],
  ar: [
    {
      language: 'es',
      contentTypes: { ticker: 'tickerLatAm_es' },
    },
  ],
  ba: [
    {
      language: 'hr',
      contentTypes: { ticker: 'tickerHr_hr' },
    },
    {
      language: 'en',
      contentTypes: { ticker: 'tickerHr_hr' },
    },
    {
      language: 'bs',
      contentTypes: { ticker: 'tickerHr_hr' },
    },
    {
      language: 'sr',
      contentTypes: { ticker: 'tickerRs_sr' },
    },
  ],
  be: [
    {
      language: 'fr',
      contentTypes: { ticker: 'tickerBe_fr' },
    },
  ],
  ch: [
    {
      language: 'it',
      contentTypes: { ticker: 'tickerIt_it' },
    },
  ],
  es: [
    {
      language: 'es',
      contentTypes: { ticker: 'tickerEs' },
    },
  ],
  fr: [
    {
      language: 'fr',
      contentTypes: { ticker: 'tickerFr' },
    },
  ],
  gb: [
    {
      language: 'en',
      contentTypes: { ticker: 'tickerGb_en' },
    },
  ],
  hr: [
    {
      language: 'hr',
      contentTypes: { ticker: 'tickerHr_hr' },
    },
    {
      language: 'en',
      contentTypes: { ticker: 'tickerHr_hr' },
    },
  ],
  ie: [
    {
      language: 'en',
      contentTypes: { ticker: 'tickerGb_en' },
    },
  ],
  it: [
    {
      language: 'it',
      contentTypes: { ticker: 'tickerIt_it' },
    },
  ],
  lu: [
    {
      language: 'fr',
      contentTypes: { ticker: 'tickerBe_fr' },
    },
  ],
  mc: [
    {
      language: 'fr',
      contentTypes: { ticker: 'tickerFr' },
    },
  ],
  md: [
    {
      language: 'ro',
      contentTypes: { ticker: 'tickerRo_ro' },
    },
  ],
  me: [
    {
      language: 'sr',
      contentTypes: { ticker: 'tickerRs_sr' },
    },
  ],
  mx: [
    {
      language: 'es',
      contentTypes: { ticker: 'tickerLatAm_es' },
    },
  ],
  ro: [
    {
      language: 'ro',
      contentTypes: { ticker: 'tickerRo_ro' },
    },
  ],
  rs: [
    {
      language: 'sr',
      contentTypes: { ticker: 'tickerRs_sr' },
    },
    {
      language: 'sr',
      contentTypes: { ticker: 'tickerRs_sr' },
    },
  ],
  sm: [
    {
      language: 'it',
      contentTypes: { ticker: 'tickerIt_it' },
    },
  ],
  ua: [
    {
      language: 'uk',
      contentTypes: { ticker: 'tickerUa_uk' },
    },
    {
      language: 'ru',
      contentTypes: { ticker: 'tickerUa_uk' },
    },
  ],
  uy: [
    {
      language: 'es',
      contentTypes: { ticker: 'tickerLatAm_es' },
    },
  ],
  va: [
    {
      language: 'it',
      contentTypes: { ticker: 'tickerIt_it' },
    },
  ],
};
